import { BotStyler } from './bot-styler';
import simpleUpdateIn from 'simple-update-in';
import 'custom-event-polyfill';

import axios from 'axios';
const { VUE_APP_API_URL } = process.env;
var localToken, defaultPayload;
const VERSION = '2';
export class BotInit {
  constructor() {
    this.chatToken = {};
    this.botConfig = {};
    this.botDefaultStrings = {};
    this.botOpen = false;
  }

  getChatToken(botToken, apiToken) {
    axios
      .post('https://directline.botframework.com/v3/directline/conversations', true, {
        headers: { Authorization: `Bearer ${botToken}` }
      })
      .then(({ data }) => {
        this.chatToken = data;
        this.createWebChat(data.token, apiToken);
      });
  }

  bind(fn, context) {
    return function() {
      fn.apply(context, arguments);
    };
  }

  getBotConfiguration(token, payload) {
    const user = JSON.parse(window.localStorage.getItem('onboarding_user'));
    const url = `${VUE_APP_API_URL}/v${VERSION}/bot-configuration?languageId=${user.languageId}`;
    localToken = token;
    defaultPayload = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(({ data }) => {
          this.botConfig = data;
          this.botConfig.userAvatarImage = payload.userAvatarImage;
          this.getChatToken(data.botSecretKey, token);
          resolve();
        })
        .catch(reject);
    });
  }

  getMessages(token) {
    // const VUE_APP_API_URL = this.botConfig.baseUrl;
    const user = JSON.parse(window.localStorage.getItem('onboarding_user'));
    const url = `${VUE_APP_API_URL}/v${VERSION}/bot-configuration/default-strings?languageId=${user.languageId}`;
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(({ data }) => {
      this.botDefaultStrings = data;
    });
  }

  createWebChat(botToken, apiToken) {
    const styler = new BotStyler();
    let styleOptions = styler.getStyleOptions(this.botConfig);
    const tokenPayload = this.parseJwt(apiToken);
    const description = this.botConfig.widget.description;
    const forwardingEmail = this.botConfig.forwardEmail;
    const companyId = defaultPayload.companyId;
    const onboardeeId = defaultPayload.onboardeeId;
    const templateId = defaultPayload.templateId;
    const userLanguage = this.botConfig.language ? this.botConfig.language : defaultPayload.language;
    const unrecognizedIntentResponse = this.botConfig.widget.unrecognizedIntentResponse;
    var outerscope = this;
    var store = window.WebChat.createStore({}, function(reff) {
      var dispatch = reff.dispatch;
      return function(next) {
        return function(action) {
          if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
            window.$chatbotDispatch = dispatch;
            dispatch({
              payload: {
                name: 'webchat/join',
                value: {
                  email: forwardingEmail,
                  introduction: description,
                  isUserFirstLogin: defaultPayload.isFirstTimeAccess,
                  language: userLanguage,
                  token: apiToken,
                  userId: tokenPayload.nameid ? tokenPayload.nameid : defaultPayload.userId,
                  userName: tokenPayload.unique_name ? tokenPayload.unique_name : defaultPayload.userName,
                  utcMinutesOffset: new Date().getTimezoneOffset() * -1,
                  unrecognizedIntentResponse,
                  companyId,
                  onboardeeId,
                  templateId
                }
              },
              type: 'WEB_CHAT/SEND_EVENT'
            });
            outerscope.hideOrShow();
          }

          if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
            var event = new CustomEvent('webchatincomingactivity');
            event.data = action.payload.activity;
            window.dispatchEvent(event);
          }

          if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {
            // var token = localStorage.getItem("token");
            var token = localToken;
            const userLanguage = userLanguage ? userLanguage : defaultPayload.language;
            const userId = tokenPayload.nameid ? tokenPayload.nameid : defaultPayload.userId;
            // const userName = tokenPayload.unique_name ? tokenPayload.unique_name : defaultPayload.userName;
            action = simpleUpdateIn(action, ['payload', 'activity', 'channelData', 'token'], function() {
              return token;
            });
            // action = simpleUpdateIn(action, ["payload", "activity", "value", "language"], function() {
            //   return userLanguage;
            // });
            action = simpleUpdateIn(action, ['payload', 'activity', 'value', 'userId'], function() {
              return userId;
            });
            // action = simpleUpdateIn(action, ["payload", "activity", "value", "userName"], function() {
            //   return userName;
            // });
          }

          return next(action);
        };
      };
    });

    window.WebChat.renderWebChat(
      {
        directLine: window.WebChat.createDirectLine({
          token: botToken
        }),
        groupTimestamp: false,
        locale: window.navigator.language,
        resize: 'detect',
        store,
        styleOptions: styleOptions,
        userID: tokenPayload.nameid
      },
      document.getElementById('webchat')
    );
    styler.applyCustomStyles(this.botConfig);
    document.querySelector('#webchat > *').focus();
    var that = this;
    window.addEventListener('webchatincomingactivity', function(ref) {
      var data = ref.data;
      //catch tasks
      if (
        data.type &&
        data.type === 'message' &&
        data.value &&
        data.suggestedActions &&
        data.suggestedActions.actions &&
        data.suggestedActions.actions.length > 0
      ) {
        var hasNumber = data.value && data.value !== '0';
        var chatBadge = document.getElementById('botBadge');
        chatBadge.innerText = data.value;
        chatBadge.style.display = hasNumber ? 'block' : 'none';
        var chatGreeting = document.getElementById('botGreeting');
        chatGreeting.style.display = hasNumber ? 'block' : 'none';

        if (hasNumber) {
          that.getMessages(apiToken);
        }
      }
    });
  }

  miniOrMaximizeChatWindow() {
    const chatWindow = document.getElementById('botDiv');
    const chatImage = document.getElementById('botMinimized');

    const chatBadge = document.getElementById('botBadge');
    const chatGreeting = document.getElementById('botGreeting');
    chatBadge.style.display = 'none';
    chatGreeting.style.display = 'none';

    if (this.botOpen === true) {
      chatWindow.style.display = 'none';
      chatImage.style.display = 'block';
      this.botOpen = false;
    } else {
      chatWindow.style.display = 'block';
      chatImage.style.display = 'none';
      this.botOpen = true;
    }
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  hideOrShow() {
    //show introtext && after 7am: maximize
    //otherwise: minimize

    var showWelcomeText =
      localStorage.getItem('bot.isFirstLogin') === true &&
      this.botConfig &&
      this.botConfig.widget &&
      this.botConfig.widget.description &&
      this.botConfig.widget.description.length > 0;

    const isAfterSeven = new Date().getHours() > 7;

    //reverse status
    this.botOpen = !(showWelcomeText === true && isAfterSeven === true);
    this.miniOrMaximizeChatWindow();
  }
}
