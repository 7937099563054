import './bot-styles.css';
import { BotInit } from './bot-init';
import { BotLogger } from './bot-logger';

const template =
  '\
<div id="botDiv">\
<div id="botTitleBar">\
  <div id="assistentImageDiv" style="margin-top: 1rem">\
    <img id="assistentImage" src="" alt="ai" />\
  </div>\
  <div id="titleBarText">\
    <b id="assistentName"></b>\
    <small id="assistentDescription"></small>\
 </div>\
 <div id="buttonDiv">\
    <p id="closingButton">\
      X\
    </p>\
 </div>\
</div>\
<div id="webchat" role="main"></div>\
</div>\
<div id="botMinimized">\
    <div id="botBadge"></div>\
    <div id="botGreeting"></div>\
    <img id="botButton" alt="talmundo chat logo"/>\
</div>\
';

var chatBot = new BotInit();
var logger = new BotLogger();
var inputToken = window.localStorage.getItem('onboarding_token');
var defaultPayload = { language: 'en-US', userId: 0, userName: 'name', isFirstTimeAccess: true };

const init = (token = window.localStorage.getItem('onboarding_token'), payload = defaultPayload) => {
  inputToken = token;
  defaultPayload = payload;
  const div = document.createElement('div');
  div.id = 'chatbotWrapper';
  div.innerHTML = template;
  document.body.appendChild(div);
  div.querySelector('#botButton').onclick = function() {
    changeBotWindow();
  };
  div.querySelector('#botTitleBar').onclick = function() {
    changeBotWindow();
  };

  chatBot.getBotConfiguration(token, payload).then(() => {
    if (window.innerWidth >= 200 && window.innerWidth <= 450) {
      logFullScreen();
    } else {
      logPartiallyScreen();
    }
  });
};

function changeBotWindow() {
  chatBot.miniOrMaximizeChatWindow();
  if (!inputToken) {
    return;
  }
  const token = parseJwt(inputToken);
  const event = logger.createEvent('Chat resize', 'WebChat.Event.ChatSize', {
    field: 'Resize performed',
    userId: token.nameid
  });

  logger.publishEvent(event, chatBot);
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

function logFullScreen() {
  if (!inputToken) {
    return;
  }
  const token = parseJwt(inputToken);

  const event = logger.createEvent('Chat full screen', 'WebChat.Event.ChatSize', {
    field: 'Chat is full screen',
    userId: token.nameid
  });
  logger.publishEvent(event, chatBot);
}

function logPartiallyScreen() {
  if (!inputToken) {
    return;
  }
  const token = parseJwt(inputToken);

  const event = logger.createEvent('Chat partially screen', 'WebChat.Event.ChatSize', {
    field: 'Chat is partially screen',
    userId: token.nameid
  });
  logger.publishEvent(event, chatBot);
}

var orientationQuery = window.matchMedia('(orientation: portrait)');

var orientationHandler = function(portraitOrientation) {
  if (!inputToken) {
    return;
  }
  const token = parseJwt(inputToken);
  let event;
  if (portraitOrientation.matches) {
    event = logger.createEvent('Chat orientation changed', 'WebChat.Event.ChatSize', {
      field: 'Orientation changed to portrait',
      userId: token.nameid
    });
  } else {
    event = logger.createEvent('Chat orientation changed', 'WebChat.Event.ChatSize', {
      field: 'Orientation changed to landscape',
      userId: token.nameid
    });
  }
  logger.publishEvent(event, chatBot);
};

orientationQuery.addListener(orientationHandler);

export default init;
